@import "../global";

// If you want to override variables do it here
@import "variables";

// Select2
@import "~select2/dist/css/select2.min.css";

// Import styles
@import "~air-datepicker/dist/css/datepicker.min.css";
@import "~@coreui/coreui/scss/coreui";

// Fix inaccessible dropdown in .table-responsive dropdown buttons
.table-responsive {
    overflow-x: inherit;
    td:not(.btn-td) {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 200px;
    }
}

// Fix mobile footer
.app-footer {
    flex: 0 0;
    padding-top: map-get($spacers, 3);
    padding-bottom: map-get($spacers, 3);
}

.card .breadcrumb {
    border: none;
}

// Custom styles
@import "custom";
