
.blocks-content{ position: relative; display: block; min-height: 20px;
    $bcpt: 31px;
    $bcpb: 31px;
    box-sizing: border-box; padding: $bcpt 0 $bcpb; border: 1px solid $gray-300;
    .btn{ border-radius: 0;}

    .abs-top-left-bl, .abs-top-right-bl,.abs-bot-left-bl, .abs-bot-right-bl{ position: absolute; display: block; height: $bcpt;
        h6{ padding: .5rem; margin: 0;}
    }
    .abs-top-left-bl{ top: 0; left: 0;}
    .abs-top-right-bl{ top: 0; right: 0;}
    .abs-bot-left-bl{ bottom: 0; left: 0;}
    .abs-bot-right-bl{ bottom: 0; right: 0;}

    .abs-bot-all-bl{ position: absolute; display: block; height: $bcpb; bottom: -1px; left: -1px; right: -1px;
        .abs-lft-bl,
        .abs-rgt-bl{ padding: 0 15px; box-sizing: border-box;}
        .abs-lft-bl{ width: 200px;}
        .abs-rgt-bl{ width: calc(100% - 200px); font-size: 0;}
        .btn{ border-radius: 0; margin-right: 10px;
            &:hover{ color: #fff;}
        }
        .info{ line-height: $bcpb; }
    }

    .box{ position: relative; display: block; padding: .5rem;
        background: $light; border: 1px solid darken($gray-100, 5%);
        box-shadow: 1px 1px 2px #e7ecef50;
        transition: transform .2s, opacity .2s; will-change: transform, opacity;
        &:not(:last-child){ margin-bottom: .5rem;}
    }
    &[data-sorted="false"]{
        [data-sorting-show="true"]{ display: none;}
    }
    &[data-sorted="true"]{
        [data-sorting-show="false"]{ display: none;}
        .box{cursor: all-scroll;border: 1px solid $info;}
    }
    .blocks-content-inn{ position: relative; padding: .5rem;}
}

[data-settings="settings-stat-one"]{ position: relative;
    .svg-code{position: relative; width: 100%; height: 0; padding-bottom: 35%; margin: 0 0 8px;
        box-sizing: border-box;border: 1px solid #c2cfd6;border-radius: 6px;
        svg{ position: absolute; display: block; width: 90%; height: 90%;
            top: 0; left: 0; right: 0; bottom: 0; margin: auto; fill: #000; }
        & ~ textarea{ height: 120px;}
    }
    &.for-svg{
        textarea{ height: 120px;}
    }
    .row{position: relative;}

    .btn[data-settings="setting-stat-remove"]{ position: absolute; top: 0; right: 15px; opacity: 0; width: 24px; height: 28px; z-index: 2;
        transform: translateX(150%);
        transition: color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out,
        opacity 0.25s ease,
        transform 0.25s ease;
        & + * { transition: width .25s;}
    }
    &:hover {
        .btn[data-settings="setting-stat-remove"] { opacity: 1; transform: translateX(0);
            & + * { width: calc(100% - 30px);}
        }
    }
}

.box{
    &.sortable-chosen{ opacity: .7; transform: scale3d(1.008,1.016,1);}
    .fa-grip-vertical{ }
    /* header */
    .box-header{ display: flex; justify-content: space-between; align-items: center; }
    .box-title{ font-size: 0; }
    .box-title-one{ position: relative; display: inline-block; vertical-align: middle; margin-right: 10px; height: 28px;
        .tit{ display: inline-block; width: 100%; padding: 0.25rem 0.5rem; border: 1px solid #c2cfd6;
            font-size: 0.765625rem; line-height: 1.5; color: #3e515b;}
        &.name{ width: 200px;
            .tit{border-color: transparent;}
        }
        &.type{  width: 160px; background: $gray-300;
            .tit{ border-color: darken($gray-300, 5%); color: $white;}
        }

    }

    .box-buttons{ }
    /* body */
    .box-body{  padding-top: .25rem;}
    .box-text-abs{ position: absolute; top: 4px; left: .5rem; width: 320px; }
    .box-footer{ display: flex; justify-content: space-between; align-items: center;
        border-top: 1px solid $gray-100; padding-top: .25rem;

    }
    .box-info{ color: $gray-300;}
    .btn-sm{ padding: 0.15rem 0.25rem; line-height: 1.2;
        .svg-inline--fa{ height: 16px;}
    }
    .box-text .form-group{ margin-bottom: .5rem;
        [data-box="box-stat-all"] { margin-top: -.5rem; padding-bottom: 2px;
            &.row{ position: relative; overflow: hidden; margin-left: -5px; margin-right: -5px; }
            .col-md-2, .col-md-3, .col-md-4, .col-md-6 { margin-top: .5rem; padding-left: 5px; padding-right: 5px;}
            &.sorting{
                .btn[data-box="box-stat-remove"]{ visibility: hidden;}
                [data-box="box-stat-one"]:before{ content: ''; position: absolute; display: block; height: 100%; top: 0; left: 5px; right: 5px; margin: auto;
                    background: $secondary; opacity: .25; cursor: all-scroll; z-index: 1;}
            }
        }
    }
    [data-box="box-stat-one"]{ position: relative;
        &:hover {
            .btn[data-box="box-stat-remove"] { opacity: 1; transform: translateX(0);}
            .btn[data-box="box-stat-remove"] + * { width: calc(100% - 30px);}
        }
        .btn[data-box="box-stat-remove"] + * { transition: width .25s;}
        .btn[data-box="box-stat-remove"]{ position: absolute; top: 0; right: 5px; opacity: 0; width: 24px; height: 28px;
            transform: translateX(150%);
            transition: color 0.15s ease-in-out,
                        background-color 0.15s ease-in-out,
                        border-color 0.15s ease-in-out,
                        box-shadow 0.15s ease-in-out,
                        opacity 0.25s ease,
                        transform 0.25s ease;}
        .img-wrap{ position: relative; width: 100%; padding-bottom: 56.25%; height: 0; overflow: hidden;
            img,video{ position: absolute; display: block; width: 100%; height: 100%; top: 0; bottom: 0; left: 0; right: 0; object-fit: cover; }
            img{pointer-events: none;}
            &.hight{ padding-bottom: 135%;
               img,video { object-fit: contain;}
            }
            &.square{ padding-bottom: 100%;
               img,video { object-fit: contain;}
            }
            &.thumb{ padding-bottom: 78%;
               img,video { object-fit: contain;}
            }
        }
    }
    .gall-one-wrap{ border-radius: .25rem; padding: .5rem; background: $white;
        user-select: none; box-sizing: border-box; box-shadow: 1px 1px 2px #95a5af50; }
    [data-box="inner-sort"]{
        span.show-on-sort{ display: none;}
        span.hide-on-sort{ display: block;}
        &[data-sort-enable="true"]{
            span.show-on-sort{ display: block;}
            span.hide-on-sort{ display: none;}
        }
    }


    &[data-editing="false"]{
        [data-box="edit-one"]{ display: none;}
    }
    &[data-editing="true"]{
        [data-box="show-one"]{ display: none;}
    }

    /**
    * redactor
    */
    .redactor-toolbar-wrapper{ position: absolute; top: -32px; left: 0;}
    .redactor-box.redactor-styles-on .redactor-toolbar { padding: 0;}
    .redactor-styles { padding: 6px 8px;}
    .redactor-air a.re-button-icon,
    .redactor-toolbar a.re-button-icon { padding: 5px 10px 2px 10px;}

    .wrap-typograph{ position: relative; box-shadow: 0 2px 2px rgba(90,240,155,.5);
        &:before{ content: 'Â§'; position: absolute; top: 0; left: -5px; font-size: 10px; line-height: 20px; color: $green; }
    }


    &-shell{ position: relative; overflow: hidden; user-select: none; box-sizing: border-box; border-radius: .25rem;
        background: #fafdff; border: 1px solid darken(#fafdff, 5%); box-shadow: 0 1px 2px #95a5af50;
        &--first { padding: .7rem; background: #f6f9fb; border: 1px solid darken(#f6f9fb, 5%); box-shadow: 0 1px 4px #95a5af50; }
        &--second{ padding: .6rem; background: #eff2f4; border: 1px solid darken(#eff2f4, 5%); box-shadow: 0 1px 3px #95a5af50; }
    }
}
