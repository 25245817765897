.btn,.btn-sm, .btn-group-sm > .btn{
    border-radius: .25rem;
    .svg-inline--fa{ height: 20px;}
}

.app.sidebar-hidden .breadcrumb{
    border: none;
    padding: 0;
    margin: 0;
    line-height: 30px;
}

.app-header.navbar .navbar-brand{ background-color: $white; }

.one-active{ position: relative; display: inline-block; vertical-align: middle; line-height: 30px;
    margin-right: 15px;
    small{ display: inline-block; vertical-align: middle;}
    .switch.switch-3d{ display: inline-block; vertical-align: middle; margin: 0;}
}

.switch-label {

    &.switch-mini {
        @include switch-size(40px, $switch-sm-height, $switch-sm-font-size, $switch-handle-margin);
    }
}

.redactor-wrap {
    position: relative;
    margin-top: 1rem;
    padding-top: 1.5rem;
}

.pos-top-right {
    position: absolute;
    top: 0;
    right: 0;

    &.top-minus {
        top: -0.5rem;
    }
}

.lang-flag,
.lang-line {
    &:before {
        content: '';
        display: inline-block;
        vertical-align: text-bottom;
        width: 20px;
        height: 20px;
        margin-right: 5px;
        border-radius: 50%;
        box-shadow: 0 2px 6px rgba(0, 0, 0, .35);
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
    }
    &.ru::before { background-image: url("./ru.svg"); }
    &.en::before { background-image: url("./en.svg"); }
    &.de::before { background-image: url("./de.svg"); }
}
.lang-line {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 5px;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    background: #dfe3e7;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .1);
    font-size: 10px;
    font-weight: 500;

    &::before {
        width: 18px;
        height: 18px;
        margin-right: 8px;
    }
    &.de { background-color: #E7E6D6;}
    &.en { background-color: #E7E1E7;}
    &.ru { background-color: #D8DFE7;}
}

.sidebar {
    .nav-link {
        &:hover { color: $th-blue;
            i{color: $th-blue;}
        }
        &.active {
            background: $th-blue;
            i {
                color: #fff;
            }
            &:hover {
                color: #fff;
            }
        }
    }
}
.sidebar-nav{
    .nav{
        .nav-link{ transition: background .2s ease,color .2s ease,padding .2s ease;
            backface-visibility: hidden;
            &:before{transition: background .2s ease, left .2s ease;}
            i{transition: color .2s ease;}
            //&:hover{ color: $red;}

            &.nav-dropdown-toggle{
                .badge{margin-right: 15px;}
            }
        }
        .nav-dropdown{ padding: 5px 0;}
        .nav-item{
            .nav-dropdown-items{
                .nav-link{
                    &:before{ content: ""; position: absolute; top: 50%; left: 4px; opacity: 0; display: block; width: 8px; height: 8px; padding: 0; margin-top: -4px;
                        background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'><path fill='#20a8d8' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/></svg>");
                        background-repeat: no-repeat; background-position: center;
                        transition: transform .3s ease,left .2s ease,opacity .2s ease; transform: rotate(180deg);}

                }
            }
        }
        .nav-item.open{
            .nav-link{ background: $gray-700;
                i{color: $blue;}
                &.nav-dropdown-toggle{color: $blue;
                    &:before{ background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'><path fill='#20a8d8' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/></svg>");}
                }
            }
            .nav-dropdown-items{
                .nav-link{background: lighten($gray-700, 5%);
                    &:hover{padding-left: 28px;
                        &:before{left: 16px; opacity: 1;}
                    }
                }
                .nav-link.active{background: $th-blue; padding-left: 28px;
                    &:hover{ color: #fff; cursor: default;background: darken($th-blue, 5%);}
                    &:before{left: 16px; opacity: 1;}
                }
            }
        }

    }
}
.app-header.navbar .header-nav {
    height: 55px;
    .nav-item{ padding: 17px;
        &.active{background: $th-blue;}
    }
}


.dropdown-menu,
.sidebar,
.app-header,
.card
{
    box-shadow: 1px 1px 2px #036A7450;
}


.tag__list-item{  padding: 0.25rem .75rem;}
.tag__list-span{ display: block; height: 35px;padding: 0.375rem 0.75rem;
    border: 1px solid transparent; box-sizing: border-box;}


#errors { position: fixed; bottom: 0; right: 0; left: $sidebar-width;
    box-sizing: border-box; padding: 9px 15px;
    background: $white; border-top: 1px solid $gray-200; box-shadow: 1px 1px 2px #036A7450;
    .alert:last-child{ margin-bottom: auto;}
}
.app.sidebar-hidden #errors{ left: 0;}

.textarea-min{ height: 100px;}


.upload-card{position: relative;display: block;width: 100%;
    justify-content: space-between;
    .img{position: relative;max-width:300px;
        padding: 5px; border: 1px solid $gray-200; margin-bottom:10px; display: inline-block;
        img{display: inline-block;width:100%;}
    }
    .btns{display: block;width: 92px;
        flex-wrap: wrap;align-content: space-between;
        .btn{display: block;width:100%;margin: 0;border-radius: 0;}
    }
}


.width-actions{
    max-width: 120px;
}


.c-r_right {
    .upload-card{ width: 100%;
        .img{ max-width: 100%;
            img{ max-width: inherit; }
        }
    }
}


.row-record{
    .col-record{ position: relative; display: block; width: 100%; min-height: 1px;
        padding-left: 15px; padding-right: 15px;
        &.c-r_left{ flex-basis: 0; flex-grow: 1; max-width: 100%;}
        &.c-r_right{ flex: 0 0 282px; max-width: 282px;}
    }
}
.btn,.btn-sm, .btn-group-sm > .btn{
    border-radius: .25rem;
    .svg-inline--fa{ height: 20px;}
}

.select2-container{ font-size: 0.765625rem; line-height: 1.5;}
.select2-container--default .select2-selection--single{ border-radius: 0; border-color: $gray-200;
    .select2-selection__rendered{ line-height: 26px;}
}


.btn-svg{
    position: relative;
    display: inline-block;
    vertical-align: middle;
    font-size: inherit;
    width: 16px;
    height: 20px;
    transition: all 0.15s ease-in-out;
}

.btn-outline-primary{
    .btn-svg{fill: $blue;}
}
.btn-outline-secondary{
    .btn-svg{fill: $gray-500;}
}
.btn-outline-success{
    .btn-svg{fill: $green;}
}
.btn-outline-info{
    .btn-svg{fill: $cyan;}
}
.btn-outline-warning{
    .btn-svg{fill: $yellow;}
}
.btn-outline-danger{
    .btn-svg{fill: $red;}
}
.btn-outline-dark{
    .btn-svg{fill: $dark;}
}

.btn-info,
.btn-primary,
.btn-secondary,
.btn-success,
.btn-warning,
.btn-danger,
.btn-dark,
.btn-outline-primary:hover,
.btn-outline-secondary:hover,
.btn-outline-success:hover,
.btn-outline-info:hover,
.btn-outline-warning:hover,
.btn-outline-danger:hover,
.btn-outline-dark:hover
{
    .btn-svg{fill: #fff;}
}


.house {
    &-wrap {
        display: flex;
    }
    &-canvas {
        position: relative;
        display: block;
        width: 640px;

        &__controls {
            position: absolute;
            top: 5px;
            right: 5px;
            z-index: 2;
            color: $secondary;
            display: inline-block;
            text-align: right;
            pointer-events: none;
            opacity: 0;

            &.showed {
                opacity: 1;
                pointer-events: all;
            }
        }

        &__image {
            position: relative;

            img {
                position: relative;
                display: block;
                width: 100%;
                z-index: 0;
            }

            [data-house-canvas] {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                display: block;
                width: 100%;
                height: 100%;
            }
        }
    }
}
